import React from 'react';

const Error = () => {
  const errorStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    color: '#333',
    textAlign: 'center',
  };

  const faceStyle = {
    fontSize: '100px',
    marginBottom: '20px',
  };

  const textStyle = {
    fontSize: '24px',
    marginBottom: '10px',
  };

  const suggestionStyle = {
    fontSize: '16px',
    color: '#666',
  };

  return (
    <div style={errorStyle}>
      {/* Sad face using emoji */}
      <div style={faceStyle}>😢</div>
      <div style={textStyle}>404. That’s an error.</div>
      <div style={suggestionStyle}>
        The requested URL was not found on this server.
      </div>
    </div>
  );
};

export default Error;
