import React from "react";
import Error from "./Pages/Error";
import DownloadApp from "./Pages/DownloadApp";

const App = () => {
  return (
    <div>
      <Error />
      {/* <DownloadApp /> */}
    </div>
  );
};

export default App;
